import {
    Container,
    Row,
    Col,
    Card,
    ListGroup,
    Button,
    DropdownButton,
    Dropdown
} from 'react-bootstrap';

import { ErrorRow } from '../error';

import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, orderBy, OrderByDirection, limit, startAfter, QueryDocumentSnapshot, QueryConstraint, getDocs, startAt } from 'firebase/firestore';
import { auth, firestore, functions } from '../../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { SpinnerRow } from '../spinner';
import { useEffect, useState } from 'react';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import ConfirmDialog from './elements/confirmDialog';
import PaginationRow from './elements/pagination';
import HeaderRow from './elements/header';
import AddCourseTypeModal, { CourseType } from './courseTypes_addModal';


type Sort = {
    key: string,
    title: string,
    direction: OrderByDirection,
}

const CourseTypes = () => {
    // User
    const [, userLoading,] = useAuthState(auth);

    // Sort
    const [sort, setSort] = useState<Sort>({ key: 'added', title: 'Skapad', direction: 'desc' })

    function changeSort(sort: Sort) {
        setConstraints([limit(itemsPerPage)]);
        setSort(sort);
    }

    // Pagination
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [firstDocs, setFirstDocs] = useState<QueryDocumentSnapshot[]>([]);
    const [lastDocs, setLastDocs] = useState<QueryDocumentSnapshot[]>([]);

    const [constraints, setConstraints] = useState<QueryConstraint[]>([limit(itemsPerPage)]);

    const [canGoNext, setCanGoNext] = useState<boolean>(false);

    function resetConstraints() {
        setConstraints([limit(itemsPerPage)]);
        setSort({ key: 'added', title: 'Skapad', direction: 'desc' });
        setPageNumber(1);
    }


    function next() {

        if (lastDocs) {
            setConstraints([startAfter(lastDocs[pageNumber]), limit(itemsPerPage)])
            setPageNumber(pageNumber + 1);
        } else {
            console.log("Next error");
        }
    }

    function prev() {

        if (firstDocs) {
            if (pageNumber - 1 <= 1) {
                console.log("Current page is 1, reset pagination");
                setConstraints([limit(itemsPerPage)]);
                setPageNumber(1);
            } else {
                setConstraints([startAt(firstDocs[pageNumber - 1]), limit(itemsPerPage)])
                setPageNumber(pageNumber - 1);
            }
        } else {
            console.log("Prev error");
        }

    }

    // Course types
    const [courseTypes, courseTypesLoading, courseTypesError] = useCollection(query(collection(firestore, 'courseTypes'), orderBy(sort.key, sort.direction), ...constraints), {
        snapshotListenOptions: {
            includeMetadataChanges: true
        }
    });

    // Pagination
    useEffect(() => {
        async function updatePaginationPossibilities(lastDoc: QueryDocumentSnapshot) {

            // Check if we can go forward
            const nextItemQuery = query(collection(firestore, 'courseTypes'), orderBy(sort.key, sort.direction), startAfter(lastDoc), limit(1));
            const nextSnapshot = await getDocs(nextItemQuery);
            const nextItemLength = nextSnapshot.docs.length
            if (nextItemLength > 0) {
                setCanGoNext(true);
            } else {
                setCanGoNext(false);
            }
        };
        if (courseTypes) {
            const firstDoc = courseTypes.docs[0];
            let arrayFirst = firstDocs;

            arrayFirst[pageNumber] = firstDoc;
            setFirstDocs(arrayFirst);

            const lastDoc = courseTypes.docs[courseTypes.docs.length - 1];
            let arrayLast = lastDocs;
            arrayLast[pageNumber] = lastDoc;
            setLastDocs(arrayLast);

            updatePaginationPossibilities(lastDoc);

        }
    }, [courseTypes, firstDocs, lastDocs, pageNumber, sort.direction, sort.key]);

    useEffect(() => {
        setConstraints([limit(itemsPerPage)]);
    }, [itemsPerPage]);

    // Delete & Add
    const [executeDelete, deleteExecuting, deleteError] = useHttpsCallable(
        functions,
        'deleteCourseType'
    );

    const [executeAdd, addExecuting, addError] = useHttpsCallable(
        functions,
        'addCourseType'
    );

    function handleDeleteClick(id: string) {
        setDeleteID(id);
        setShowDeleteDialog(true);
    }

    function handleDeleteConfirm() {
        setShowDeleteDialog(false);
        executeDelete({ id: deleteID })
    }

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [deleteID, setDeleteID] = useState<string>("")
    const handleCloseDeleteDialog = () => setShowDeleteDialog(false);

    const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
    const handleCloseAddDialog = () => { setShowAddDialog(false) };


    function handleAddClick() {
        setShowAddDialog(true);
    }

    function handleSaveCourse(data: CourseType) {
        console.log(data);
        setShowAddDialog(false);
        executeAdd(data)
            .then(() => {
                resetConstraints();
            })
    }

    useEffect(() => {
        if (deleteError) {
            alert(deleteError);
        }

    }, [deleteError]);

    useEffect(() => {
        if (addError) {
            alert(addError);
        }

    }, [addError]);

    function MainPage() {
        return (
            <>
                <div className='menu-spacer'></div>
                <Container className='courseListContainer'>
                    <HeaderRow currentSort={sort} sortOptions={[
                        { key: 'added', title: 'Skapad', direction: 'desc' },
                        { key: 'course', title: 'Namn', direction: 'asc' }
                    ]}
                    changeSort={changeSort} itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} sortDisabled={false}>
                        <Button variant="success" onClick={handleAddClick}>Ny kurstyp</Button>
                    </HeaderRow>
                    <Row>
                        <Col>
                            <CourseList />
                        </Col>
                    </Row>
                    <PaginationRow canGoPrev={pageNumber <= 1 ? false : true} canGoNext={canGoNext} onNext={next} onPrev={prev} />
                </Container>
                <ConfirmDialog visible={showDeleteDialog} onAbort={handleCloseDeleteDialog} onDelete={handleDeleteConfirm} title={'Bekräfta'} message={'Är du säker på att du vill ta bort kurstypen?'} />
                <AddCourseTypeModal visible={showAddDialog} onAbort={handleCloseAddDialog} onSave={handleSaveCourse} courseTypes={courseTypes} />
            </>
        )
    }

    function CourseList() {
        if (courseTypesError) {
            return (<ErrorRow messages={[courseTypesError.message]} />);
        } else if (userLoading || courseTypesLoading || deleteExecuting || addExecuting) {
            return (<SpinnerRow />);
        } else if (courseTypes != null && courseTypes.docs.length > 0) {
            return (
                <Card>
                    <ListGroup variant="flush">
                        {
                            courseTypes.docs.map(doc => {
                                return (
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center"
                                        id={
                                            doc.id
                                        }
                                        key={
                                            doc.id
                                        }>
                                        <div>
                                            <div className="courseName">
                                                {doc.data().course}
                                            </div>
                                        </div>
                                        <div>
                                            <DropdownButton id="dropdown-basic-button" title="Åtgärder">
                                                <Dropdown.Item onClick={() => { handleDeleteClick(doc.id) }} >Radera</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </ListGroup.Item>

                                )
                            })
                        } </ListGroup>
                </Card>
            )
        } else {
            return (
                <Row className='spinnerRow'>
                    <Col className="d-flex justify-content-center">
                        Inga kurstyper
                    </Col>
                </Row>
            )
        }
    }

    return (
        <MainPage />
    );

};

export default CourseTypes;
