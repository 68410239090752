import { Button, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import FloatingLabel from 'react-bootstrap/FloatingLabel';

interface ParticipantsModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (text: string) => void;
  onAbort: () => void;
  title: string;
  label: string;
  doneButton: string;
  participantList?: string[];
}

const ParticipantsModal = (props: ParticipantsModalProps): JSX.Element => {


  const [text, setText] = useState(props.participantList ? props.participantList.join('\n') : '');

  useEffect(() => {
    console.log(props.participantList);
    const participantList = props.participantList 
      ? props.participantList.filter(participant => participant).map(participant => 
        participant.toLowerCase().trim().replace(/\.+$/, '').split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
        ).join('\n') 
      : ''
    setText(participantList);
  }, [props.participantList]);

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setText(event.target.value);
  }

  return (
    <Modal show={props.isOpen} onHide={props.onAbort}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FloatingLabel controlId="textArea" label={props.label}>
          <Form.Control
            as="textarea"
            placeholder={props.label}
            style={{ height: '150px'}}
            onChange={handleTextChange}
            value={text}
          />
        </FloatingLabel>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onAbort}>
          Avbryt
        </Button>
        <Button variant="success" disabled={!text} onClick={() => { props.onSubmit(text) }}>
          {props.doneButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ParticipantsModal;