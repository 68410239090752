import { generate } from "@pdfme/generator";
import { firestore } from '../firebase/firebase';
import { query, where, getDocs, collectionGroup, getDoc, or } from "firebase/firestore";
import { saveAs } from 'file-saver';
import signPainter from "./SignPainter.ttf";
import roboto from "./Roboto.ttf";
import helvetica from "./Helvetica.ttf";
import pdfMeTemplate from '../pdfme/template.json';

async function exportCertificate(courseId: string, courseName: string, instructor: string, organizer: string, courseDate: string, participants: string[]){

  // Why is this line here? It's not used.
  // let course = await getCourseInfo(courseId);

  (async () => {

    const font = {
      SignPainter: {
        data: await fetch(signPainter).then(r => r.arrayBuffer()),
        fallback: false
      },
      Helvetica: {
        data: await fetch(helvetica).then(r => r.arrayBuffer()),
        fallback: true
      },
      Roboto: {
        data: await fetch(roboto).then(r => r.arrayBuffer()),
        fallback: false
      }
    }
    const template = pdfMeTemplate;

    const inputs = [
    {
      "participants": participants.join(', '),
      "courseName": courseName.trim(),
      "courseInfo": organizer.trim() + ", " + courseDate,
      "instructorName": instructor.trim(),
    }
    ];
  
  inputs.forEach((input) => {
    Object.entries(input).forEach(([key, value]) => {
      if (value == null || value.trim() === '') {
        const message = `${key} is null, undefined, or whitespace`;
        alert("Ogiltigt Intyg: " + message);
        throw new Error(message);
      }
    });
  });

    const pdf = await generate({ template, inputs , options: { font }});
  
    saveAs(
      new Blob([new Uint8Array(pdf.buffer)], { type: "application/octet-stream" }),
      `Intyg.pdf`
  );

  })();

}

async function getCourseInfo(courseId: string) {

  const q = query(collectionGroup(firestore, 'certificates'), where('courseId', '==', courseId));

  const querySnapshot = await getDocs(q);

  let participants: string[] = [];

  console.log(querySnapshot.docs);

  for (const doc of querySnapshot.docs) {

      const parent = doc.ref.parent.parent;
      const courseData = doc.data();

      if (courseData) {
        //courseName = courseData.course;
        //instructor = courseData.instructor;
        console.log(JSON.stringify(courseData));
      }

      if (parent) {

          const parentUser = await getDoc(parent);

          const parentUserData = parentUser.data();

          if (parentUserData) {
              participants.push(parentUserData.name);
          } else {
              console.log("Error: parent data is null");
          }

      } else {
          console.log("Error: parent is null");
      }

  };

  return {participants: participants};

}
export default exportCertificate;